import { useToaster } from 'react-hot-toast'
import { motion, AnimatePresence } from 'framer-motion'

const toastVariants = {
  hidden: {
    opacity: 0,
    x: -10
  },
  visible: {
    opacity: 1,
    x: 0
  }
}

export function Notifications() {
  const { toasts, handlers } = useToaster()
  const { startPause, endPause } = handlers

  return (
    <motion.div
      className={`fixed top-0 md:bottom-0 md:top-auto left-0 p-4 md:p-8 w-full z-50 ${
        !toasts.length ? 'hidden' : ''
      }`}
      layout
    >
      <AnimatePresence>
        {toasts.map(toast => {
          let bgColor = 'bg-gray-300'
          let textColor = 'text-gray-900'
          switch (toast.type) {
            case 'success':
              bgColor = 'bg-green-300'
              textColor = 'text-green-900'
              break
            case 'error':
              bgColor = 'bg-red-300'
              textColor = 'text-red-900'
              break
            case 'loading':
              bgColor = 'bg-yellow-300'
              textColor = 'text-yellow-900'
              break
          }

          return (
            <motion.div
              variants={toastVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              key={toast.id}
              onHoverStart={startPause}
              onHoverEnd={endPause}
              className={`h-full sm:max-w-xs md:max-w-md mt-4 p-4 rounded-md shadow-xl ${bgColor} ${textColor} z-50`}
              layout
            >
              {toast.message}
            </motion.div>
          )
        })}
      </AnimatePresence>
    </motion.div>
  )
}
