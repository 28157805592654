import { DefaultSeo } from 'next-seo'
import * as Sentry from '@sentry/node'
import { Hydrate } from 'react-query/hydration'
import { SkeletonTheme } from 'react-loading-skeleton'
import { QueryClient, QueryClientProvider } from 'react-query'

import { theme } from '../tailwind.config'
import { Notifications } from '@components/Notifications'
import { UserContextProvider } from '@hooks/user/context'

// Root Tailwind CSS styles with resets
import 'tailwindcss/tailwind.css'

// Reach UI base styles
import '@reach/dialog/styles.css'
import '@reach/listbox/styles.css'
import '@reach/tooltip/styles.css'
import '@reach/combobox/styles.css'

import 'styles/global.css'
import 'styles/blog.css'
import 'styles/faqs.css'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
  })
}

const queryClient = new QueryClient()

function App({ Component, pageProps, err }) {
  return (
    <UserContextProvider>
      <SkeletonTheme
        color={theme.extend.colors.gray[200]}
        highlightColor={theme.extend.colors.gray[300]}
      >
        <Notifications />
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <DefaultSeo
              titleTemplate="%s | Majordomo"
              title="The Second Half of Your Home Inspection"
              description="Estimate home inspection and maintenance repair costs. Upload any inspection report. Get accurate local pricing for all repair items in 24 hours. 24/7, 365-days."
              canonical="https://majordomo.com"
              openGraph={{
                type: 'website',
                url: 'https://majordomo.com',
                title: 'Majordomo',
                description: 'The Second Half of Your Home Inspection',
                images: [
                  {
                    url: '/logo/opengraph.png',
                    width: 1200,
                    height: 630,
                    alt: 'Majordomo Logo on a white background'
                  },
                  {
                    url: '/logo/twitter.png',
                    width: 1200,
                    height: 600,
                    alt: 'Majordomo Logo on a white background'
                  }
                ]
              }}
              twitter={{
                site: '@majordomohq',
                cardType: 'summary_large_image'
              }}
            />
            <Component {...pageProps} err={err} />
          </Hydrate>
        </QueryClientProvider>
      </SkeletonTheme>
    </UserContextProvider>
  )
}

export default App
